window._ = require('lodash');

try {
    require('bootstrap');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

const modalDay = document.getElementById('modalDay');
const modalStartTime = document.getElementById('modalStartTime');
const modalEndTime = document.getElementById('modalEndTime');
const modalBreakTime = document.getElementById('modalBreakTime');
const modalComment = document.getElementById('modalComment');
const modalTargetDay = document.getElementById('modalTargetDay')

// 日報登録モーダル表示
const toggle = document.querySelectorAll('[data-bs-target="#myModal"]');
toggle.forEach((target) => {
  target.addEventListener('click', () => {
    const date = target.closest('tr').querySelector('th').dataset.date;
    const startTime = target.closest('tr').querySelector('td:nth-child(2)').textContent;
    const endTime = target.closest('tr').querySelector('td:nth-child(3)').textContent;
    const breakTime = target.closest('tr').querySelector('td:nth-child(4)').textContent;
    const comment = target.closest('tr').querySelector('td:nth-child(5)').textContent;
    const targetDay = target.closest('tr').querySelector('td:nth-child(6)').dataset.day;

    modalDay.textContent = date;
    modalStartTime.value = startTime;
    modalEndTime.value = endTime;
    if (breakTime === '0:00') {
      modalBreakTime.options[0].selected = true;
    }
    if (breakTime === '0:30') {
      modalBreakTime.options[1].selected = true;
    }
    if (breakTime === '0:45') {
      modalBreakTime.options[2].selected = true;
    }
    if (breakTime === '1:00') {
      modalBreakTime.options[3].selected = true;
    }
    modalComment.textContent = comment;
    modalTargetDay.value = targetDay;
  })
})

// 開始時間の打刻
const startBtn = document.getElementById('startBtn');
startBtn?.addEventListener('click', () => {
  const now = new Date();
  const formattedNow = now.getHours().toString().padStart(2, '0') + ":" + now.getMinutes().toString().padStart(2, '0');
  modalStartTime.value = formattedNow;
})

// 終了時間の打刻
const endBtn = document.getElementById('endBtn');
endBtn?.addEventListener('click', () => {
  const now = new Date();
  const formattedNow = now.getHours().toString().padStart(2, '0') + ":" + now.getMinutes().toString().padStart(2, '0');
  modalEndTime.value = formattedNow;
})

// 業務パターンボタン
const patternBtn = document.getElementById('patternBtn');
patternBtn?.addEventListener('click', () => {
  modalStartTime.value = patternBtn.querySelector('span:nth-child(1)').dataset.time;
  modalEndTime.value = patternBtn.querySelector('span:nth-child(2)').dataset.time;
  modalBreakTime.value = patternBtn.querySelector('span:nth-child(3)').dataset.time;
})

// 社員情報編集モーダル表示
const modalTargetNo = document.getElementById('modalTargetNo');
const modalUserNo = document.getElementById('modalUserNo');
const modalAuthType = document.getElementById('modalAuthType');
const modalUserName = document.getElementById('modalUserName');
const modalUserEmail = document.getElementById('modalUserEmail');
const userStartTime = document.getElementById('userStartTime');
const userEndTime = document.getElementById('userEndTime');
const userBreakTime = document.getElementById('userBreakTime');

const authTypeArea = document.getElementById('authTypeArea');

const userToggle = document.querySelectorAll('[data-bs-target="#userModal"]');
userToggle.forEach((target) => {
  target.addEventListener('click', () => {
    const myNo = authTypeArea.dataset.myNo;
    const userNo = target.closest('tr').dataset.userNo;
    const userName = target.closest('tr').dataset.userName;
    const userEmail = target.closest('tr').dataset.userEmail;
    const authType = target.closest('tr').dataset.authType;
    const startTime = target.closest('tr').dataset.startTime;
    const endTime = target.closest('tr').dataset.endTime;
    const breakTime = target.closest('tr').dataset.breakTime;

    modalTargetNo.value = userNo;
    modalUserNo.textContent = ( '000' + userNo ).slice( -4 );

    while(authTypeArea.firstChild) {
      authTypeArea.removeChild(authTypeArea.firstChild);
    };

    if(myNo !== userNo) {
      const labelAuthType = document.createElement('label');
      labelAuthType.htmlFor = "modalAuthType";
      labelAuthType.textContent = "管理者権限";

      const inputAuthType = document.createElement('input');
      inputAuthType.type = "checkbox";
      inputAuthType.name = "modal_auth_type";
      inputAuthType.className = "ms-2";
      inputAuthType.id = "modalAuthType";
      if(authType === '1') {
        inputAuthType.checked = true;
      }

      authTypeArea.appendChild(labelAuthType);
      authTypeArea.appendChild(inputAuthType);
    } else {
      const pAuthType = document.createElement('p');
      pAuthType.className = "mb-0";
      pAuthType.textContent = "管理者";
      authTypeArea.appendChild(pAuthType);
    }
    modalUserName.value = userName;
    modalUserEmail.value = userEmail;
    userStartTime.value = startTime;
    userEndTime.value = endTime;
    userBreakTime.value = breakTime;

  })
})

// 社員情報削除モーダル表示
const modalDeleteTargetNo = document.getElementById('modalDeleteTargetNo');
const modalDeleteNo = document.getElementById('modalDeleteNo');
const modalDeleteName = document.getElementById('modalDeleteName');

const userDeleteToggle = document.querySelectorAll('[data-bs-target="#userDeleteModal"]');
userDeleteToggle.forEach((target) => {
  target.addEventListener('click', () => {
    const userNo = target.closest('tr').dataset.userNo;
    const userName = target.closest('tr').dataset.userName;

    modalDeleteTargetNo.value = userNo;
    modalDeleteNo.textContent = ( '000' + userNo ).slice( -4 );
    modalDeleteName.textContent = userName;
  });
});
